import {Component, OnInit} from '@angular/core';
import {HomeService} from "../../shared/services/home/home.service";
import {AboutusService} from "../../shared/services/aboutus/aboutus.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  apiUrl = environment.basePath;
  aboutUs = [];
  chiffres = [];
  actualities = [];
  constructor(private aboutusService: AboutusService) {
  }

  ngOnInit(): void {
    // get home data
    this.aboutusService.getAboutUs().subscribe( response => {

      response.forEach( elt => {
        this.aboutUs.push(elt);

      });
    });
    this.aboutusService.getChiffres().subscribe( response => {

      response.forEach( elt => {
        this.chiffres.push(elt);

      });
    });
    this.aboutusService.getActivities().subscribe( response => {

      response.forEach( elt => {
        this.actualities.push(elt);

      });
    });
  }
  onFormSubmit() {

  }
}
