<wind-header></wind-header>

<div class="container">
  <!-- Top Navigation -->

  <div>
    <div class="wrap text-center" click="initialiser()">
      <br />
      <h1>Nos Produits</h1>
      <br />

      <div class="position-flex">
        <p
          style="
            text-align: justify;
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.7;
          "
        >
          Notre progression constante dans le design, la fabrication et la vente
          d’encres de haute qualité contribuent significativement au
          développement de l’ère numérique. Notre offre produit apporte des
          solutions innovantes pour les marchés industriels de l’impression
          d’étiquettes, de décors et de packagings. La conception de nos encres
          garantit une grande fiabilité et productivité à nos clients. Tout en
          contribuant au développement des techniques d’impression, nous
          travaillons à réduire l’impact sur l’environnement. Les formules de
          nos encres apportent les hautes performances requises par le marché de
          l’impression. Notre progression constante dans la fabrication et la
          vente d'encres de haute qualité contribuent significativement au
          développement de marché d’impression. Les encres de Zahra
          International sont à base de solvants, offrant une fixation d'encre
          améliorée, de meilleurs temps de séchage, des vitesses d'impression
          accélérées, une meilleure résistance à la rayure et une compatibilité
          plus large de supports.
        </p>
      </div>

      <div class="position-flex">
        <div class="dropdown" (click)="showDropdownUVScreen()">
          <div class="btn-small green-bright last">
            <img src="../../../assets/img/product/uv2.png" />
            <span class="label bottom">Encres FELXO </span>
          </div>
          <div class="dropdown-content" *ngIf="showUVScreend">
            <ul class="navigation__items">
              <p>
                Nos encres FELXO de la gamme PEA sont parfaitement adaptées pour
                l’impression de sacs boutique et autre en support polyoléfines.
                Cette gamme se caractérise par une haute brillance, une
                intensité des couleurs, une bonne résistance au frottement et à
                l’abrasion ainsi qu’une excellente résistance à l’eau, comme
                principales caractéristiques.
              </p>
            </ul>
          </div>
        </div>

        <div class="dropdown" (click)="showDropdownPrint()">
          <div class="btn-big blue">
            <img src="../../../assets/img/product/news.png" />
            <span class="label bottom">Série pour films </span>
          </div>
          <div class="dropdown-content" *ngIf="showPrint">
            <ul class="navigation__items">
              <p>
                Notre série pour films a été conçue pour l’impression frontale
                ou cello-émail d’emballages alimentaires et pour le secteur de
                l’hygiène
              </p>
            </ul>
          </div>
        </div>
        <div class="dropdown">
          <div class="btn-big red" (click)="showDropdownFlexography()">
            <img src="../../../assets/img/product/flex.png" />
            <span class="label bottom">Sacs de luxe</span>
          </div>

          <div class="dropdown-content" *ngIf="showFlexography" #menu>
            <ul class="navigation__items">
              <p>
                pour l’impression des sacs de luxe, de films rétractables, de
                films étirables ou industriels pour palettes et d’emballages en
                général. Les avantages de cette gamme : une excellente brillance
                ainsi qu’une très bonne résistance à l’eau.
              </p>
            </ul>
          </div>
        </div>
        <!-- 
        <div class="btn-small orange dropdown">
          <img src="../../../assets/img/product/digital.png" />
          <span class="label bottom">Digital</span>
        </div> -->

        <div class="dropdown" (click)="showDropdownPackagingInks()">
          <div class="btn-big orange">
            <img src="../../../assets/img/product/pack.png" />
            <span class="label bottom">Encres d'impression </span>
          </div>
          <div class="dropdown-content" *ngIf="showPackagingInks">
            <ul class="navigation__items">
              <p>
                Nos encres d'impression pour les sacs industriels, les sacs
                agricoles, les sacs d'engrais ainsi que pour les sachets de
                détergents. Cette série se distingue par sa grande résistance à
                la lumière et aux produits chimiques
              </p>
            </ul>
          </div>
        </div>

        <div class="dropdown" (click)="showDropdownGravure()">
          <div class="btn-small green last">
            <img src="../../../assets/img/product/gravure.png" />
            <span class="label bottom">Big bags</span>
          </div>
          <div class="dropdown-content" *ngIf="showGravure">
            <ul class="navigation__items">
              <p>
                Notre gamme d’encres, conçue spécialement pour l’impression de
                big bags, de sacs pour produits agricoles et engrais et
                emballages en films plastiques pour les lessives. Cette série se
                caractérise par une résistance élevée à la lumière et aux
                produits chimiques ainsi qu’une excellente résistance aux
                intempéries.
              </p>
            </ul>
          </div>
        </div>
      </div>

      <div class="position-flex">
        <div class="dropdown" (click)="showDropdownMetal()">
          <div class="btn-small red-light">
            <img src="../../../assets/img/product/metal.png" />
            <span class="label bottom">La gamme PTA </span>
          </div>
          <div class="dropdown-content" *ngIf="showMetal">
            <ul class="navigation__items">
              <p>
                Nos encres à base de solvants de la gamme PTA sont spécialement
                conçues pour l’impression hélio pour tout type d’emballages
                alimentaires dont la principale caractéristique est une faible
                rétention de solvants.
              </p>
            </ul>
          </div>
        </div>

        <div class="dropdown" (click)="showDropdownHeatset()">
          <div class="btn-big red-light" style="background: #d56d9b">
            <img src="../../../assets/img/product/heatset.png" />
            <span class="label bottom">Blanc à deux composants </span>
          </div>
          <div class="dropdown-content" *ngIf="showHeatset">
            <ul class="navigation__items">
              <p>
                Notre blanc à deux composants a été spécialement conçu pour
                l’impression flexo et hélio d’emballages alimentaires et
                hygiéniques. Après réticulation des composants, cette encre est
                extrêmement résistante (pasteurisation, stérilisation…).
              </p>
            </ul>
          </div>
        </div>

        <div class="dropdown" (click)="showDropdownInks()">
          <div class="btn-big purple">
            <img src="../../../assets/img/product/inks.png" />
            <span class="label bottom">Encres d'impression mates </span>
          </div>
          <div class="dropdown-content" *ngIf="showInks">
            <ul class="navigation__items">
              <p>
                Nos encres d'impression mates à haute concentration pigmentaire
                ont été spécialement développées pour la flexo et l'hélio
                extérieures de sacs en plastique et d'emballages flexibles. Les
                encres présentent une bonne résistance au pli et au frottement
                et résistent au thermoscellage.
              </p>
            </ul>
          </div>
        </div>

        <div class="dropdown" (click)="showDropdownSheetfed()">
          <div class="btn-big red-light" style="background: #828182">
            <img src="../../../assets/img/product/sheet.png" />
            <span class="label bottom">Hélio et flexo sans odeur </span>
          </div>
          <div class="dropdown-content" *ngIf="showSheetfed">
            <ul class="navigation__items">
              <p>
                Spécialement développées pour l'impression, nos encres hélio et
                flexo sans odeur s'avèrent idéales pour les couvercles dans
                l'industrie des emballages alimentaires.
              </p>
            </ul>
          </div>
        </div>
      </div>

      <!-- <div class="position-flex">
        <div class="btn-small blue dropdown">
          <img src="../../../assets/img/product/blankets.png" />
          <span class="label bottom">Blankets,Sleeves & MBB-DAY</span>
        </div>

        <div class="btn-small red-light dropdown">
          <img src="../../../assets/img/product/cleaner.png" />
          <span class="label bottom">Cleaners - VARN</span>
        </div>

        <div class="btn-big blue-nav dropdown">
          <img src="../../../assets/img/product/varn.png" />
          <span class="label bottom">Pressroom Chemicals - VARN</span>
        </div>

        <div class="dropdown" (click)="showDropdownResins()">
          <div class="btn-small redish">
            <img src="../../../assets/img/product/resins.png" />
            <span class="label bottom">Resins & Chips</span>
          </div>
          <div class="dropdown-content" *ngIf="showResins">
            <ul class="navigation__items">
              <li>
                <a href="#">Chips</a>
              </li>
              <li>
                <a href="#"> Resins</a>
              </li>

              <li></li>
            </ul>
          </div>
        </div>

        <div class="dropodown" (click)="showDropdownUV()">
          <div class="btn-big yellow">
            <img src="../../../assets/img/product/uv.png" />
            <span class="label bottom">UV Offset</span>
          </div>
          <div class="dropdown-content" *ngIf="showUV">
            <ul class="navigation__items">
              <li>
                <a href="#">Narrow Web</a>
              </li>
              <li>
                <a href="#"> Sheetfed</a>
              </li>

              <li></li>
            </ul>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="position-flex">
    <p
      style="
        text-align: justify;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.7;
        margin-top: 27px;
      "
    >
      Constamment, nous adaptons nos produits aux évolutions technologiques du
      marché en intégrant l’ingénierie de pointe à notre démarche de cohérence,
      de performance et de service pour nos clients. Nos équipes techniques
      s’appuient sur des équipements de recherche aux technologies avancées et
      sur une expertise industrielle en colorants et résines. Cela nous permet
      d’introduire sur le marché des innovations et des améliorations afin de
      demeurer à la pointe dans nos technologies. Zahra International a
      sélectionné pour vous parmi les meilleures marques du secteur des encres à
      base de solvant : une vaste gamme de produits qui vous permettra de
      choisir celle qui correspond le mieux à vos exigences. Notre encre à base
      de solvant est le choix idéal. En effet, ce type d'encre sèche facilement
      à température ambiante et permet de réaliser un grand nombre de travaux
      sur différents types de matériaux et supports. L'impression est considérée
      comme un art, mais pour pouvoir la pratiquer dans de bonnes conditions, il
      est nécessaire de s'équiper de produits de grande qualité, et c'est pour
      cette raison que notre société propose les meilleures encres à base de
      solvant présentes sur le marché Ne manquez pas l'occasion de bénéficier du
      rapport qualité/prix exceptionnel de Zahra International et achetez dès
      maintenant votre encre
    </p>
  </div>
</div>

<br /><br />

<wind-footer></wind-footer>
