import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HomeService} from "../../services/home/home.service";

@Component({
  selector: 'wind-slick-multi-slider',
  templateUrl: './slickMultiSlide.component.html',
  styleUrls: ['./slickMultiSlide.component.scss']
})
export class SlickMultiSlideComponent implements OnInit {

  slideConfig = {slidesToShow: 4, slidesToScroll: 1, autoPlay: true,  dots: true, };

  apiUrl = environment.basePath;
  slides = [];
  slidesdefault = [ {img: 'assets/img/confiance/s1.png'}];
  constructor(private homeservice: HomeService) {
  }

  ngOnInit(): void {
    // get home data
    this.homeservice.getPartenaires().subscribe( response => {

      response.forEach( elt => {
        this.slides.push(elt.imgUrl);

      });
    });
  }

  addSlide() {
    this.slides.push({img: 'http://placehold.it/350x150/777777'});
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

}
