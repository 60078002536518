import {Component, OnInit} from '@angular/core';
import {APPLICATION_BOOTSTRAPPED} from './shared/constants/event';
import {EventManagerService} from './shared/services/event/event-manager.service';
declare var $: any;

@Component({
  selector: 'wind-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

   


  title = 'monorepo';
  constructor(private eventManager: EventManagerService) {
  }

  public ngOnInit(): void {
    this.eventManager.broadcast(APPLICATION_BOOTSTRAPPED);
    $('.hello').hide();

  }
}
