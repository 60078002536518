

<div class="section">
    <div class="container">
      <div>
       <h3 class="text-uppercase confiance">ils nous font confiance</h3>

      </div>
      <div class="section">
        <wind-slick-multi-slider></wind-slick-multi-slider>
      </div>

    </div>
</div>
