import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AUTHENTICATION_SUCCESS} from '../constants/event';
import {AUTHENTICATION_KEY} from '../constants/local-storage-keys';
import {EventManagerService} from '../services/event/event-manager.service';
import {LocalStorageService} from '../services/local-storage/local-storage.service';

@Injectable()
export class AuthTokenPersistenceInterceptor implements HttpInterceptor {

  constructor(private storageService: LocalStorageService,
              private eventManager: EventManagerService,
              private logger: NGXLogger) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse && event.body.token) {
          this.storageService.setItem(AUTHENTICATION_KEY, event.body.token).subscribe(_ => {
            this.eventManager.broadcast(AUTHENTICATION_SUCCESS);
            this.logger.trace('authentication token saved');
          });
        }
      })
    );
  }
}
