import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {AuthRoutingModule} from './auth-routing.module';
import {WindAuthLoginComponent} from './wind-auth-login/wind-auth-login.component';
import {WindAuthMainComponent} from './wind-auth-main/wind-auth-main.component';
import {WindAuthPasswordResetComponent} from './wind-auth-password-reset/wind-auth-password-reset.component';
import {WindAuthRegisterComponent} from './wind-auth-register/wind-auth-register.component';


@NgModule({
  declarations: [WindAuthMainComponent, WindAuthLoginComponent, WindAuthRegisterComponent, WindAuthPasswordResetComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule
  ]
})
export class AuthModule {
}
