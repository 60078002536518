import {Component, ElementRef, HostListener, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import { $ } from 'protractor';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
   showFlexography: boolean ;
   showPrint: boolean ;
   showGravure: boolean ;
   showMetal: boolean ;
   showHeatset: boolean ;
   showBlankets: boolean ;
   showSheetfed: boolean ;
   showUVScreend: boolean ;
   showInks: boolean ;
   showPackagingInks: boolean ;
  showUV: boolean ;
  showResins: boolean ;
  public text: String;


    
      constructor(){}
    
    
      

  ngOnInit(): void {
  
  }

  onFormSubmit() {

  }
  initialiser(){
    this.showFlexography = false;
    this.showPrint = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showHeatset=false
    this.showInks=false;
  }
  

  showDropdownFlexography() {
    this.showFlexography = !this.showFlexography;
    this.showPrint = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showHeatset=false
    this.showInks=false;
  }

  showDropdownPrint() {
    this.showPrint = !this.showPrint;
    this.showFlexography = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showHeatset=false
    this.showInks=false;
  }

  showDropdownGravure() {
    this.showGravure = !this.showGravure;
    this.showFlexography = false;
    this.showPrint = false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showHeatset=false
    this.showInks=false;
  }

  showDropdownMetal() {
    this.showMetal = !this.showMetal;
    this.showFlexography = false;
    this.showPrint = false;
    this.showGravure =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showHeatset=false
    this.showInks=false;
  }

  showDropdownHeatset() {
    this.showHeatset = !this.showHeatset;
    this.showFlexography = false;
    this.showPrint = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showInks=false;
  }

  showDropdownBlankets() {
    this.showBlankets = !this.showBlankets;
    this.showFlexography = false;
    this.showPrint = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showHeatset=false
    this.showInks=false;
  }

  showDropdownSheetfed() {
    this.showSheetfed = !this.showSheetfed;
    this.showFlexography = false;
    this.showPrint = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showHeatset=false
    this.showInks=false;
  }

  showDropdownUVScreen() {
    this.showUVScreend = !this.showUVScreend;
    this.showFlexography = false;
    this.showPrint = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showHeatset=false
    this.showInks=false;
  }

  showDropdownInks() {
    this.showInks = !this.showInks;
    this.showFlexography = false;
    this.showPrint = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showHeatset=false
  }

  showDropdownPackagingInks() {
    this.showPackagingInks = !this.showPackagingInks;
    this.showFlexography = false;
    this.showPrint = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showUV =false;
    this.showResins =false;
    this.showHeatset=false
    this.showInks=false;
  }

  showDropdownUV() {
    this.showUV = !this.showUV;
    this.showFlexography = false;
    this.showPrint = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showResins =false;
    this.showHeatset=false
    this.showInks=false;
  }

  showDropdownResins() {
    this.showResins = !this.showResins;
    this.showFlexography = false;
    this.showPrint = false;
    this.showGravure =false;
    this.showMetal =false;
    this.showBlankets =false;
    this.showSheetfed =false;
    this.showUVScreend =false;
    this.showInks =false;
    this.showPackagingInks =false;
    this.showUV =false;
    this.showHeatset=false
    this.showInks=false;
  }
}


function viewChildren(arg0: string) {
  throw new Error('Function not implemented.');
}

