import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from "../../../../../environments/environment";
import {HomeService} from "../../../services/home/home.service";

@Component({
  selector: 'wind-footer',
  templateUrl: './wind-footer.component.html',
  styleUrls: ['./wind-footer.component.scss']
})
export class WindFooterComponent implements OnInit {
  apiUrl = environment.basePath;
  aides = [];
  rxSociaux = [];
  constructor(private homeservice: HomeService) {
  }

  ngOnInit(): void {
    // get home data
    this.homeservice.getAides().subscribe( response => {

      response.forEach( elt => {
        this.aides.push(elt);

      });
    });
    this.homeservice.getRxSociaux().subscribe( response => {

      response.forEach( elt => {
        this.rxSociaux.push(elt);

      });
    });
  }

}
