import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WindHomeComponent} from './wind-home-main/wind-home.component';
import {ProductComponent} from "../product/product/product.component";
import {WindShowmoreComponent} from "./wind-showmore/wind-showmore.component";


const ROUTES: Routes = [
  {
    path: '',
    component: WindHomeComponent,
  },

  {
    path: 'showmore',
    component: WindShowmoreComponent,
    // loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
    // canActivate: [AuthorizedGuard],
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES)
  ],
  exports: [RouterModule]
})
export class HomeRoutingModule {
}
