
<div *ngIf="img === undefined " class="imgsec" style="background-image: url('http://www.collab2.co.za/wp-content/uploads/2017/06/contact-us-background.jpg'); background-size: cover;">
<!--  <h1 class="title">Contacter nous</h1>-->
  <div class="container mar">

  </div>
</div>
<div *ngIf="img !== undefined " class="imgsec" style="background-size: cover;">
  <!--  <h1 class="title">Contacter nous</h1>-->
  <img style=" height: 350px; width: 100%; " src="{{apiUrl}}{{img}}">
  <div class="container mar">

  </div>
</div>
<div class="imgsec" style="background-image: url('http://www.collab2.co.za/wp-content/uploads/2017/06/contact-us-background.jpg'); background-size: cover;">
<!--  <h1 class="title">Contacter nous</h1>-->
  <div class="container mar">

  </div>
</div>

<div class="container" style="margin-top: 10%;margin-bottom: 10%;">
  <div  class="row" >
    <div class="col-md-6">
      <h3 class="title">
       {{title}}
      </h3>

      <div class="bottom"></div>

      <div class="marg">
        <p [innerHTML]="description">
           </p>

      </div>
    </div>
    <div class="col-md-6">

      <video *ngIf='url !== undefined ' style="width: 100%;"  controls src="{{url}}">
        Your browser does not support the video tag.
      </video>
<!--      <img *ngIf="img !== undefined " style="width: 100%" src="{{apiUrl}}{{img}}"  alt=""/>-->

    </div>
  </div>
  <div *ngIf="title === '' || title === undefined" class="row" >
    <div class="col-md-6">
      <h3 class="title">
        The evolution of Digital
        <br>
        Textile Printing
      </h3>

      <div class="bottom"></div>

      <div class="marg">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
          ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </p>

        <button>LIRE PLUS</button>
      </div>
    </div>
    <div class="col-md-6">

      <video  style="width: 100%;"  controls src="https://www.w3schools.com/tags/movie.ogg">
        Your browser does not support the video tag.
      </video>

    </div>
  </div>
</div>
