import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'wind-slider-without-indicators',
  templateUrl: './sliderwithoutindicators.component.html',
  styleUrls: ['./sliderwithoutindicators.component.scss']
})
export class SliderwithoutindicatorsComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}
