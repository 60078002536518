import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'wind-main',
  templateUrl: './wind-main.component.html',
  styleUrls: ['./wind-main.component.scss']
})
export class WindMainComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
