 <wind-header></wind-header>
<router-outlet></router-outlet>
<wind-footer></wind-footer> 



<!-- 

<article style="display: block; text-align: left; width: 650px; margin: 0 auto;">
	    <h1>We&rsquo;ll be back soon!</h1>
	        <div>
			        <p>Sorry for the inconvenience but we&rsquo;re performimaintenance at the momenonline shortly!</p>
				        <p>&mdash; The Team</p>
					    </div>
</article>
-->