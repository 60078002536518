import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HomeService} from "../../shared/services/home/home.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'wind-evolution',
  templateUrl: './wind-home-evolution.component.html',
  styleUrls: ['./wind-home-evolution.component.scss']
})
export class WindHomeEvolutionComponent implements OnInit {
  
  apiUrl = environment.basePath;
  video = [];
  constructor(private homeservice: HomeService,
              private route: ActivatedRoute,
              private router: Router) {
  }


  ngOnInit(): void {
    this.homeservice.getVideo().subscribe( response => {

      response.forEach( elt => {
        this.video.push(elt);

      });
    });
  }
  showmore(payload) {
    this.router.navigate(['/showmore', {img: payload.imgUrl, url: payload.url, title: payload.title, description: payload.description, type: 'evolution' }]);
  }
}
