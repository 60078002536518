import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {HomeRoutingModule} from './home-routing.module';

import {WindBusniessComponent} from './wind-busniess/wind-busniess.component';
import {WindHomeEvolutionComponent} from './wind-home-evolution/wind-home-evolution.component';
import {WindHomeProfileListComponent} from './wind-home-profileList/wind-home-profileList.component';
import {WindEncreComponent} from "./wind-Encre/wind-encre.component";
import {WindWedoComponent} from "./wind-weDo/wind-wedo.component";
import {WindConfianceComponent} from "./wind-confiance/wind-confiance.component";
import {WindShowmoreComponent} from "./wind-showmore/wind-showmore.component";




@NgModule({
  declarations: [WindHomeProfileListComponent, WindHomeEvolutionComponent, WindBusniessComponent, WindEncreComponent, WindWedoComponent, WindConfianceComponent, WindShowmoreComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    HomeRoutingModule,
    FormsModule
  ],
  exports: [

    WindHomeProfileListComponent, WindHomeEvolutionComponent, WindBusniessComponent, WindEncreComponent, WindWedoComponent, WindConfianceComponent
  ],
})
export class HomeModule {
}
