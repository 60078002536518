import {Component, OnInit} from '@angular/core';
import {HomeService} from "../../shared/services/home/home.service";


@Component({
  selector: 'wind-home',
  templateUrl: './wind-home.component.html',
  styleUrls: ['./wind-home.component.scss']
})
export class WindHomeComponent implements OnInit {

  constructor(private service: HomeService) {
  }
  ngOnInit(): void {

  }
  test(){
    this.service.getBanner().subscribe(console.log);
  }

}
