import {Component, OnInit} from '@angular/core';
import {AboutusService} from "../../shared/services/aboutus/aboutus.service";

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  infos = [{
    adresse: 'adresse Tunisie',
    tel: '+216  xx xxx xxx / +216  xx xxx xxx',
    fax: '+216 xx xxx xxx',
    email: 'example@mail.com'
  }];
  contactform: {
  nom: string,
  email: string,
  object: string,
  message: string } = {
    nom: '',
    email: '',
    object: '',
    message: ''
  };
  constructor(private aboutusService: AboutusService) {
  }

  ngOnInit(): void {
    this.aboutusService.getPlusInfos().subscribe(res => {
      this.infos = res;
    });
  }
  onFormSubmit() {
    const success = document.querySelector('.alert-success');
    const danger = document.querySelector('.alert-danger');
    this.aboutusService.contactus(this.contactform).subscribe(resp => {
      console.log(resp);
      danger.classList.remove('show');
      success.classList.add('show');
      // tslint:disable-next-line:variable-name
      setTimeout(_next => { success.classList.remove('show'); }, 4000);
    });
  }
}
