import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {ContactUsRoutingModule} from './contact-us-routing.module';
import {ContactUsComponent} from './contact-us/contact-us.component';




@NgModule({
  declarations: [ContactUsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    ContactUsRoutingModule,
    FormsModule,
  ],
  exports: [
    ContactUsComponent
  ],
})
export class ContactUsModule {
}
