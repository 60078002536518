import {Component, OnInit} from '@angular/core';
import {HomeService} from "../../shared/services/home/home.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'wind-busniess',
  templateUrl: './wind-busniess.component.html',
  styleUrls: ['./wind-busniess.component.scss']
})
export class WindBusniessComponent implements OnInit {
  BusinessSegment = [];
  apiUrl = environment.basePath;
  constructor(private homeservice: HomeService) {
  }

  ngOnInit(): void {
    this.homeservice.getBusinessSegment().subscribe( response => {

      response.forEach( elt => {
        this.BusinessSegment.push(elt.imgUrl);
      });
    });
  }

}
