import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HomeService} from "../../shared/services/home/home.service";
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'wind-encre',
  templateUrl: './wind-encre.component.html',
  styleUrls: ['./wind-encre.component.scss']
})
export class WindEncreComponent implements OnInit {
  apiUrl = environment.basePath;
  ancres = [];
  ancresList = new BehaviorSubject([]);
  constructor(private homeservice: HomeService,
              private router: Router) {
  }

  ngOnInit(): void {
    // get home data
    this.homeservice.getEncre().subscribe( response => {

      response.forEach( elt => {
        this.ancres.push(elt);
      });
      this.ancresList.next(this.ancres);
    });
  }
  selectTonner() {
    this.ancres = [];
    this.ancresList.next([]);
    this.homeservice.getToner().subscribe( response => {
      response.forEach( elt => {
        this.ancres.push(elt);
      });
      this.ancresList.next(this.ancres);
    });
  }
  selectEncre() {
    this.ancres = [];
    this.ancresList.next([]);
    this.homeservice.getEncre().subscribe( response => {
      response.forEach( elt => {
        this.ancres.push(elt);
      });
      this.ancresList.next(this.ancres);
    });

  }
  showmore(payload) {
    this.router.navigate(['/showmore', { img: payload.imgUrl, title: payload.title, description: payload.description, type: 'encre' }]);
  }
}
