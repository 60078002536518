import {Component, OnInit} from '@angular/core';
import {HomeService} from '../../services/home/home.service';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'wind-slick-slider',
  templateUrl: './slickSlider.component.html',
  styleUrls: ['./slickSlide.component.scss']
})
export class SlickSlideComponent implements OnInit {
  
  apiUrl = environment.basePath;
  slidesdefault = [
    {img: 'assets/img/slider/3.jpg',
      img2:'assets/img/slider/11.jpg',
    img3:'assets/img/slider/15.jpg',
    img4:'assets/img/slider/13.jpg',
    img5:'assets/img/slider/9.jpg',
    img6:'assets/img/slider/4.jpg',

  }
  ];
  slides = [];

  //slideConfig = {slidesToShow: 1, slidesToScroll: 1, autoPlay: true,  dots: true, };
  slideConfig = {  enabled: true,
    autoplay: true,
    draggable: false,
    autoplaySpeed: 3000,dots: true, };

   constructor(private homeservice: HomeService) {
   }

  ngOnInit(): void {
    
    // get home data
    this.homeservice.getBanner().subscribe( response => {

      response.forEach( elt => {
        this.slides.push(elt.imgUrl);

      });
    });
  }
  addSlide() {
    this.slides.push({img: 'http://placehold.it/350x150/777777'});
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  

}

