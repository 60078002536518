import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TRANSLOCO_CONFIG, TranslocoConfig, TranslocoModule} from '@ngneat/transloco';
import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {environment} from '../environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {FakeApiService} from './fake/service/fake-api.service';

import {HomeModule} from './home/home.module';
import {WindHomeComponent} from './home/wind-home-main/wind-home.component';
import {SharedModule} from './shared/shared.module';
import {translocoLoader} from './transloco.loader';
import {WindContactComponent} from "./contact-us/contact-main/wind-contact.component";
import {ContactUsModule} from "./contact-us/contact-us.module";


@NgModule({
  declarations: [
    AppComponent,
    WindHomeComponent,
    WindContactComponent
  ],

  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule,
    NgbModule,
    // !environment.production ?
    //   HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {delay: 3000}) : [],
    TranslocoModule,
    SharedModule,
    HomeModule,
    ContactUsModule
  ],

  providers: [{
    provide: TRANSLOCO_CONFIG,
    useValue: {
      listenToLangChange: true,
      defaultLang: 'en',
      prodMode: environment.production
    } as TranslocoConfig
  },
    translocoLoader
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
