import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'wind-confiance',
  templateUrl: './wind-confiance.component.html',
  styleUrls: ['./wind-confiance.component.scss']
})
export class WindConfianceComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
