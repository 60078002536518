import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, timeout} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {SKIP_AUTH_INTERCEPTOR_HEADER} from '../../constants/header';
@Injectable({
  providedIn: 'root'
})
export class AboutusService {
  apiUrl = environment.basePath;
  constructor(private http: HttpClient) {
  }

  public getAboutUs(): Observable<Array<any>> {

    return this.http.get<Array<any>>(`${this.apiUrl}/api/how_are_wes?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public getChiffres(): Observable<Array<any>> {

    return this.http.get<Array<any>>(`${this.apiUrl}/api/chiffres?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public getActivities(): Observable<Array<any>> {

    return this.http.get<Array<any>>(`${this.apiUrl}/api/actualities?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public getPlusInfos(): Observable<Array<any>> {

    return this.http.get<Array<any>>(`${this.apiUrl}/api/plusinfos?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public contactus(payload): Observable<any> {

    return this.http.post<any>(`${this.apiUrl}/api/contacts?published=true`, payload,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}).pipe(map(response => {
      return response['hydra:member'];
    }));
  }

}
