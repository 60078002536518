import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'wind-not-found',
  templateUrl: './wind-not-found.component.html',
  styleUrls: ['./wind-not-found.component.scss']
})
export class WindNotFoundComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
