<ngx-slick-carousel *ngIf="slides.length === 0" class="carousel"
                    #slickModal="slick-carousel"
                    [config]="slideConfig"
                    (init)="slickInit($event)"
                    (breakpoint)="breakpoint($event)"
                    (afterChange)="afterChange($event)"
                    (beforeChange)="beforeChange($event)">
  <div ngxSlickItem *ngFor="let slide of slidesdefault" class="slide">
    <img src="{{ slide.img }}" alt="" width="100%" class="img">
  </div>
</ngx-slick-carousel>
<ngx-slick-carousel *ngIf="slides.length !== 0" class="carousel"
                    #slickModal="slick-carousel"
                    [config]="slideConfig"
                    (init)="slickInit($event)"
                    (breakpoint)="breakpoint($event)"
                    (afterChange)="afterChange($event)"
                    (beforeChange)="beforeChange($event)">
  <div ngxSlickItem *ngFor="let slide of slides" class="slide">
    <img src="{{apiUrl}}{{ slide }}" alt="" width="100%" class="img">
  </div>
</ngx-slick-carousel>


<!--<button (click)="addSlide()">Add</button>-->
<!--<button (click)="removeSlide()">Remove</button>-->
<!--<button (click)="slickModal.slickGoTo(2)">slickGoto 2</button>-->
<!--<button (click)="slickModal.unslick()">unslick</button>-->
