import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'wind-profileList',
  templateUrl: './wind-home-profileList.component.html',
  styleUrls: ['./wind-home-profileList.component.scss']
})
export class WindHomeProfileListComponent implements OnInit {



  constructor() {
  }

  ngOnInit(): void {
  }

}
