import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, timeout} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {SKIP_AUTH_INTERCEPTOR_HEADER} from '../../constants/header';
@Injectable({
  providedIn: 'root'
})
export class HomeService {
  apiUrl = environment.basePath;
  constructor(private http: HttpClient) {
  }

  public getBanner(): Observable<Array<any>> {

    return this.http.get<Array<any>>(`${this.apiUrl}/api/galleries?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}).pipe(map(response => {
      return response['hydra:member'];
    }));
  }

  public getBusinessSegment(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.apiUrl}/api/secteur_acts?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}
      ).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public getEncre(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.apiUrl}/api/ancre_tonners?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}
      ).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public getToner(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.apiUrl}/api/tonners?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}
      ).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public getWeDo(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.apiUrl}/api/what_we_dos?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}
      ).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public getPartenaires(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.apiUrl}/api/partenaires?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}
      ).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public getAides(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.apiUrl}/api/mentions?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}
      ).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public getRxSociaux(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.apiUrl}/api/reseau_socials?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}
      ).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
  public getVideo(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.apiUrl}/api/videos?published=true`,
      {headers: new HttpHeaders().set(SKIP_AUTH_INTERCEPTOR_HEADER.name, SKIP_AUTH_INTERCEPTOR_HEADER.value)}
      ).pipe(map(response => {
      return response['hydra:member'];
    }));
  }
}
