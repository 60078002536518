

<div class="section"  >

  <div class="" style="background-image: url('../../../assets/img/maquette.jpg');     background-size: cover;">
    <h3 class="title">Business Segments</h3>
    <div class="container mar">
    <div *ngIf="BusinessSegment.length===0" class="row">
      <div class="col-md-4" >
        <img style=" width: 300px; height: 300px; " src="../../../../../assets/img/df/1.png">
      </div>
      <div class="col-md-4">
        <img style=" width: 300px; height: 300px; " src="../../../../../assets/img/df/2.png">
      </div>
      <div class="col-md-4">
        <img style=" width: 300px; height: 300px; " src="../../../../../assets/img/df/3.png">
      </div>
    </div>
    <div *ngIf="BusinessSegment.length!==0" class="row">
        <div class="col-md-4" *ngFor="let segment of BusinessSegment">
          <img style=" width: 300px; height: 300px; " src="{{apiUrl}}{{segment}}">
        </div>
    </div>
  </div>
</div>
