import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, timeout} from 'rxjs/operators';
import {GLOBAL_AJAX_TIMEOUT} from '../../../constants/global';
import {TOKEN_REFRESH_HEADER} from '../../../constants/header';
import {Account} from '../../../model/data/account';
import {Authentication} from '../../../model/request-payload/authentication';
import {Register} from '../../../model/request-payload/register';
import {Token} from '../../../model/response-payload/token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: string = '/api';
  authenticationToken: Token;

  constructor(private http: HttpClient) {
  }

  authenticate(payload: Authentication): Observable<Token> {
    return this.http.get<Token>(`${this.baseUrl}/authentications`, payload).pipe(
      //timeout(GLOBAL_AJAX_TIMEOUT),
      map((response: any) => {
        return new Token(response.token);
      })
    );
  }

  refreshToken(token: string): Observable<Token> {
    return this.http.post(`${this.baseUrl}/authenticate/refresh`, token, {
      headers: new HttpHeaders().append(TOKEN_REFRESH_HEADER.name, TOKEN_REFRESH_HEADER.value)
    }).pipe(
      timeout(GLOBAL_AJAX_TIMEOUT),
      map((response: string) => new Token(response))
    );
  }

  register(payload: Register): Observable<Account> {
    return this.http.post<Account>(`${this.baseUrl}/register`, payload).pipe(
      timeout(GLOBAL_AJAX_TIMEOUT),
      map((response: any) => new Account())/*TODO map account data*/
    );
  }

  logout(): Observable<any> {
    return this.http.get('api/logout').pipe(
      timeout(GLOBAL_AJAX_TIMEOUT)
    );
  }
}
