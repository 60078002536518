<section class="section">
  <div class="container">
    <!-- Custom controls -->
    <div class="row">
      <div class="card">
        <div class="card-header">
          auth container
        </div>
        <div class="card-bodey">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</section>
