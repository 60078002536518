import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HomeService} from "../../shared/services/home/home.service";

@Component({
  selector: 'wind-wedo',
  templateUrl: './wind-wedo.component.html',
  styleUrls: ['./wind-wedo.component.scss']
})
export class WindWedoComponent implements OnInit {
  apiUrl = environment.basePath;
  weDo = [];
  constructor(private homeservice: HomeService) {
  }

  ngOnInit(): void {
    // get home data
    this.homeservice.getWeDo().subscribe( response => {

      response.forEach( elt => {
        this.weDo.push(elt);

      });
    });
  }


}
