
<div class="container">
  <div *ngIf="video.length === 0" class="row" >
    <div class="col text-center">
      <h3 class="title">
        The evolution of Digital
        <br>
        Textile Printing
      </h3>

      <!-- <div class="bottom"></div> -->

      <div class="marg">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
          ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </p>

        <button>LIRE PLUS</button>
      </div>
    </div>
    
  </div>
  <div *ngIf="video.length !== 0" class="row" >
    <div class="col-md-6">
      <h3 class="title">
        {{video[0].title}}
      </h3>

      <div class="bottom"></div>

      <div class="marg">
        <p [innerHTML]="video[0].description  | slice:0:550">
           </p>

        <button (click)="showmore(video[0])">LIRE PLUS</button>
      </div>
    </div>
    <div class="col-md-6 mt-5">

      <!--<iframe width="560" height="315" src="{{video[0].url}}"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

      </iframe>-->

      <iframe width="560" height="315" src="https://www.youtube.com/embed/JNWm0hkcqXA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</div>
