import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'wind-contact',
  templateUrl: './wind-contact.component.html',
  styleUrls: ['./wind-contact.component.scss']
})
export class WindContactComponent implements OnInit {


  ngOnInit(): void {

  }


}
