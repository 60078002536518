import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'wind-wind-auth-password-reset',
  templateUrl: './wind-auth-password-reset.component.html',
  styleUrls: ['./wind-auth-password-reset.component.scss']
})
export class WindAuthPasswordResetComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
