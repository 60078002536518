import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule, MatInputModule} from '@angular/material';
import {RouterModule} from '@angular/router';
import {NgbCollapseModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {ToastrModule} from 'ngx-toastr';
import {WindAutoCompleteComponent} from './components/auto-complete/wind-auto-complete.component';
import {WindLanguageItemComponent} from './components/language-select/wind-language-item.component';
import {WindLanguageSelectComponent} from './components/language-select/wind-language-select.component';
import {WindLatestSearchComponent} from './components/latest-search/wind-latest-search.component';
import {WindFooterComponent} from './components/layout/wind-footer/wind-footer.component';
import {WindHeaderComponent} from './components/layout/wind-header/wind-header.component';
import {WindMainComponent} from './components/layout/wind-main/wind-main.component';
import {WindNotFoundComponent} from './components/not-found/wind-not-found.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import {SliderwithoutindicatorsComponent} from './components/sliderwithoutindicators/sliderwithoutindicators.component';
import {AuthTokenInjectorInterceptor} from './interceptor/auth-token-injector-interceptor';
import {AuthTokenPersistenceInterceptor} from './interceptor/auth-token-persistence-interceptor';
import {AppInitializerProvider} from './services/app-initializer-provider';
import {SlickSlideComponent} from "./components/slickSlider/slickSlide.component";
import {SlickMultiSlideComponent} from "./components/slickMultiSlider/slickMultiSlide.component";

export function appInitializerFactory(provider: AppInitializerProvider) {
  return () => provider.hookToAppInitEvent();
}

@NgModule({

  declarations: [WindHeaderComponent, WindFooterComponent, WindMainComponent,
    WindNotFoundComponent, WindLatestSearchComponent, WindLanguageSelectComponent,
    WindLanguageItemComponent, WindAutoCompleteComponent,
    SliderwithoutindicatorsComponent, SlickSlideComponent, SlickMultiSlideComponent],
  exports: [
    WindLatestSearchComponent,
    WindAutoCompleteComponent,
    SliderwithoutindicatorsComponent,
    SlickSlideComponent,
    SlickMultiSlideComponent,
    WindHeaderComponent,
    WindFooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LoggerModule.forRoot({level: NgxLoggerLevel.TRACE}),
    FormsModule,
    NgbCollapseModule,
    NgbDropdownModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    ToastrModule,

    SlickCarouselModule,

  ],
  providers: [
    AppInitializerProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppInitializerProvider],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenPersistenceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInjectorInterceptor,
      multi: true
    }
  ]
})
export class SharedModule {
}
