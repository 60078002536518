<div class="row">
  <div class="col-lg-12 col-sm-12">
    <form (ngSubmit)="login()" [formGroup]="form.asFormGroup">
      <div *ngFor="let field of form.fields" [ngClass]="form.getGroupValidationClass(field.name)"
           class="form-group">
        <input [formControl]="field.asFormControl"
               [ngClass]="field.inputValidationClass"
               [placeholder]="field.placeholder"
               [type]="field.type" class="form-control"/>
      </div>
      <div class="form-group">
        <button [disabled]="submitting" class="btn btn-secondary btn-icon" type="submit">
          <span class="btn-icon-label">login</span><i *ngIf="submitting" class="fa fa-spin fa-spinner"></i>
        </button>
      </div>
    </form>
  </div>
</div>
