import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HomeService} from "../../shared/services/home/home.service";
import {BehaviorSubject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";

@Component({
  selector: 'wind-showmore',
  templateUrl: './wind-showmore.component.html',
  styleUrls: ['./wind-showmore.component.scss']
})
export class WindShowmoreComponent implements OnInit {
  apiUrl = environment.basePath;
  title = '';
  img = '';
  url = '';
  description = '';
  type = '';
  constructor(private homeservice: HomeService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(rep => {
      console.log(rep['params']['title'])
      this.title = rep['params']['title'];
      this.img = rep['params']['img'];
      this.url = rep['params']['url'];
      this.description = rep['params']['description'];
      this.type = rep['params']['type'];
      }
    );
  }
}
