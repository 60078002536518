<div class="container">
<div class="row">
  <div class="col-md-5 encordiv">
    <div class="originPadd">
      <h1 class="origine">Encre et toner <br> d'origine</h1>
<br>
      <div class="end">
        <h5 style="color:white" (click)="selectEncre()">Encre Flexo <span> <img src="../../../assets/img/next.png"> </span></h5>
        <h5 style="color:white" (click)="selectTonner()">Encre Hélio <span> <img src="../../../assets/img/next.png">  </span></h5>
      </div>

    </div>
  </div>
  <div class="col-md-1"></div>

  <div *ngIf="this.ancresList.getValue().length===0" class="col-md-3 rig">
    <div>
      <h3 class="grey ">Encre pourils
        imprimante</h3>

      <p>Profitez d'impressions de documents et de photos exceptionnelles d'une grande longévité grâce aux .. </p>

      <a  class="lire" (click)="showmore(null)">LIRE PLUS</a>
    </div>
  </div>

  <div *ngFor="let ancre of this.ancresList.getValue()" class="col-md-3">
    <h3 class="grey ">{{ancre.title}}</h3>

    <p [innerHTML]="ancre.description  | slice:0:100"></p>

    <a  class="lire" (click)="showmore(ancre)">LIRE PLUS</a>

  </div>
</div>
</div>
