<div class="carousel slide" data-ride="carousel" id="carouselExampleIndicators2">

  <div class="carousel-inner">
    <div class="carousel-item active">
      <img alt="First slide" class="d-block w-100" src="assets/img/slider/3.jpg"  >
      <div class="carousel-caption  d-md-block">
        <p class="fontresp">

        </p>
      </div>
    </div>
    <div class="carousel-item">
      <img alt="Second slide" class="d-block w-100" src="assets/img/slider/11.jpg"  >
      <div class="carousel-caption  d-md-block">

      </div>
    </div>

    <div class="carousel-item">
      <img alt="Second slide" class="d-block w-100" src="assets/img/slider/15.jpg" >
      <div class="carousel-caption  d-md-block">

      </div>
    </div>
   <!---
    <div class="carousel-item">
      <img alt="Second slide" class="d-block w-100" src="assets/img/slider/13.jpg" >
      <div class="carousel-caption  d-md-block">

      </div>
    </div>
    <div class="carousel-item">
      <img alt="Second slide" class="d-block w-100" src="assets/img/slider/9.jpg" >
      <div class="carousel-caption  d-md-block">

      </div>
    </div>
    <div class="carousel-item">
      <img alt="Second slide" class="d-block w-100" src="assets/img/slider/4.jpg" >
      <div class="carousel-caption  d-md-block">

      </div>
    </div>
    
  -->
  </div>
  <a class="carousel-control-prev" data-slide="prev" href="#carouselExampleIndicators2" role="button">
    <span aria-hidden="true" class="carousel-control-prev-icon"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" data-slide="next" href="#carouselExampleIndicators2" role="button">
    <span aria-hidden="true" class="carousel-control-next-icon"></span>
    <span class="sr-only">Next</span>
  </a>

</div>

