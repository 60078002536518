<wind-header></wind-header>

<div

  class="imgsec"
  style="
    background-image: url('/assets/img/slider/13.jpg');
    
    
    background-size: cover;
    background-position: center;
  "
>
  <h1 class="title">Contacter nous</h1>
  <div class="container mar"></div>
</div>

<div class="section">
  <div class="container filtre">
    <div class="row">
      <div class="col-md-4 info">
        <h5 class="" style="margin-bottom: 2rem">Plus d'information</h5>
        <div class="media-list">
          <div class="media">
            <i class="pull-left fa fa-home iconss"></i>
            <div class="media-body">
              <strong>Adresse:</strong><br />
              Zone Industrielle Route Boumerdes Mahdia
              <!-- {{ infos[0].adresse }} -->
            </div>
          </div>
          <div class="media">
            <i class="pull-left fa fa-phone iconss"></i>
            <div class="media-body">
              <strong>Téléphone:</strong><br />
              <!-- {{ infos[0].tel }} -->
              73 655 720 
            </div>
          </div>
          <!-- <div class="media">
            <i class="pull-left fa fa-envelope-o iconss"></i>
            <div class="media-body">
              <strong>Fax:</strong><br />
              {{ infos[0].fax }}
            </div>
          </div> -->

          <div class="media">
            <i class="pull-left fa fa-cloud-download-o iconss"></i>
            <div class="media-body">
              <strong>email:</strong><br />
              <!-- {{ infos[0].email }} -->
              Contact@Zahra-ink.com
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 frm">
        <h3 class="" style="text-align: center">Contactez Nous</h3>
        <form
          #testForm="ngForm"
          (ngSubmit)="onFormSubmit(); testForm.reset()"
          ngNativeValidate
          role="form"
        >
          <div class="col-md-12">
            <input
              type="text"
              [(ngModel)]="contactform.nom"
              name="nom"
              class="form-control"
              placeholder="Entrer nom et prénom"
              required
              title="lkklkkllklk"
            />
          </div>
          <br />
          <div class="col-md-12">
            <input
              type="email"
              [(ngModel)]="contactform.email"
              required=""
              name="email"
              class="form-control"
              placeholder="Entrer email"
              title="lkklkkllklk"
            />
          </div>
          <br />
          <div class="col-md-12">
            <input
              type="text"
              [(ngModel)]="contactform.object"
              name="object"
              class="form-control"
              placeholder="Entrer sujet"
              required
              title="lkklkkllklk"
            />
          </div>
          <br />
          <div class="col-md-12">
            <textarea
              id="w3review"
              [(ngModel)]="contactform.message"
              name="message"
              name="w3review"
              rows="4"
              placeholder="Entrer messages"
              class="form-control"
              required
            ></textarea>
          </div>

          <div class="col-md-12">
            <input type="submit" class="btn btn-group-lg btn-submit" />
          </div>
          <div class="col-md-12">
            <div
              class="alert alert-success"
              role="alert"
              style="display: none; margin-top: 15%"
            >
              Succès: Votre message est envoyé avec succès
            </div>
            <div
              class="alert alert-danger"
              role="alert"
              style="display: none; margin-top: 15%"
            >
              Erreur: Votre message n'est pas envoyé!
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<wind-footer></wind-footer>
