import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AboutUsComponent} from './about-us/about-us/about-us.component';
import {ContactUsComponent} from './contact-us/contact-us/contact-us.component';
import {WindMainComponent} from './shared/components/layout/wind-main/wind-main.component';
import {WindNotFoundComponent} from './shared/components/not-found/wind-not-found.component';
import {AuthorizedGuard} from './shared/guard/authorized.guard';
import {ProductComponent} from "./product/product/product.component";


const ROUTES: Routes = [
  // {
  //   path: '',
  //   component: WindMainComponent,
  //   canActivate: [AuthorizedGuard],
  //   children: [
  //     {
  //       path: '',
  //       component: WindHomeComponent
  //     },
  //   ]
  // },

  {
    path: '',
    component: WindMainComponent,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    // canActivate: [AuthorizedGuard],
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule),
    // canActivate: [AuthorizedGuard],
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    loadChildren: () => import('./about-us/about-us.module.js').then(m => m.AboutUsModule),
    // canActivate: [AuthorizedGuard],
  },
  {
    path: 'products',
    component: ProductComponent,
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
    // canActivate: [AuthorizedGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    component: WindNotFoundComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
