import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {WindContactComponent} from "./contact-main/wind-contact.component";


const ROUTES: Routes = [
  {
    path: '',
    component: WindContactComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES)
  ],
  exports: [RouterModule]
})
export class ContactUsRoutingModule {
}
