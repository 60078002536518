import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {AUTHENTICATION_FAIL, LOGIN_ATTEMPTED} from '../../shared/constants/event';
import {WindForm, WindFormElement} from '../../shared/model/windForm';
import {EventManagerService} from '../../shared/services/event/event-manager.service';
import {EventWithContent} from '../../shared/services/event/event-with-content';

@Component({
  selector: 'wind-auth-login',
  templateUrl: './wind-auth-login.component.html',
  styleUrls: ['./wind-auth-login.component.scss']
})
export class WindAuthLoginComponent implements OnInit {

  form: WindForm = new WindForm([
    new WindFormElement('login', '', 'text', [Validators.required, Validators.min(4)], 'login'),
    new WindFormElement('password', '', 'password', [Validators.required, Validators.min(4)], 'password'),
    new WindFormElement('accountCode', '', 'text', [Validators.required, Validators.min(4)], 'account code')
  ]);
  submitting: boolean = false;

  constructor(private eventManager: EventManagerService) {
  }

  ngOnInit(): void {
    this.eventManager.on(AUTHENTICATION_FAIL, _ => this.submitting = false);
  }

  public login() {
    this.submitting = true;
    this.eventManager.broadcast(new EventWithContent<WindForm>(LOGIN_ATTEMPTED, this.form));
  }

}
