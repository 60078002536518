<wind-header></wind-header>

<div
  class="imgsec"
  style="
    /* background-image: url('http://www.collab2.co.za/wp-content/uploads/2017/06/contact-us-background.jpg'); */
    background-image: url('/assets/img/slider/2.jpg');
    background-size: cover;
    background-position: center;
    /* min-height: calc(100vh - 172px); */
  "
>
  <h1 class="title" style="color: white">Qui Somme Nous</h1>
  <div class="container mar"></div>
</div>

<div class="section">
  <div class="container filtre">
    <div class="row">
      <div *ngIf="aboutUs.length === 0" class="col-md-12 frm">
        <h3>About Us</h3>
        <p class="pText">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate veli
        </p>
        <p class="pText">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate veli
        </p>
      </div>
      <div *ngIf="aboutUs.length !== 0" class="col-md-12 frm">
        <h3>{{ aboutUs[0].title }}</h3>
        <p class="pText" [innerHTML]="aboutUs[0].description"></p>
      </div>
    </div>
  </div>
</div>

<div class="section2">
  <div class="container">
    <div class="row">
      <div class="col-md-12" style="padding: 2rem">
        <h3 class="text-white" style="text-align: center">Lorem ipsum</h3>
        <p class="text-white pText2">
          Ut enim ad minim veniam, q Lorem ipsum dolor sit amet, consecte q
          Lorem ipsum dolor sit amet, consecte q Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate veli
        </p>
      </div>

      <div *ngIf="chiffres.length === 0" class="beforecen">
        <div class="cen">
          <h3>100 %</h3>
          <p>Ut enim ad minim</p>
        </div>
        <div class="cen">
          <h3>35 +</h3>
          <p>Ut enim ad minim</p>
        </div>
        <div class="cen">
          <h3>200</h3>
          <p>Ut enim ad minim</p>
        </div>
      </div>
      <div *ngIf="chiffres.length !== 0" class="beforecen">
        <div *ngFor="let chiffre of chiffres" class="cen">
          <h3>{{ chiffre.description }}</h3>
          <p>{{ chiffre.title }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="section">
  <div class="container">
    <h3>Lorem ipsum</h3>
    <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>
    <p class="">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      veli
    </p>
  </div>
</div> -->
<div class="section">
  <div class="container">
    <h1 class="title" style="color: #000000; text-transform: none !important">
      Notre Equipe
    </h1>
  </div>
</div>




<div class="section" style="    margin: -83px;">
  <div class="container">
    <div class="row arround">
      <div *ngIf="actualities.length === 0" class="col-md-5 frm">
        <img
          src="https://www.selecteo.fr//img/cms/tache-couleur-encre-pigmentee.jpg"
        />
        <h3>About Us</h3>
        <p class="pText">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate veli
        </p>
      </div>
      <div *ngFor="let act of actualities" class="col-md-5 frm">
        <img src="{{ apiUrl }}{{ act.imgUrl }}"style="border-radius:4%" />
        <!-- <h3>{{ act.title }}</h3> -->
        <p class="pText" [innerHTML]="act.description | slice: 0:220"></p>
      </div>



      
    </div>
  </div>
  <div class="container">
    <div  class="col-md-12 frm">
      <p class="pText">
        Nous sommes une entreprise à taille humaine soutenue par les ressources,
        l’expertise et l’expérience sur le marché. Nos équipes sont engagées au
        quotidien pour apporter un savoir-faire et un savoir-être d’excellence
        auprès des clients et des consommateurs.
      </p>
      <p class="pText">
        Nous nous concentrons sur la qualité de chacun des processus que nous
        mettons en place. Nous recherchons l’excellence dans notre activité. À
        travers le professionnalisme de nos collaborateurs, nous mettons notre
        énergie au service de la qualité pour nos clients.
      </p>
      <p class="pText">
        Leur satisfaction est un objectif constant. Leur confiance renouvelée
        nous conforte dans notre travail. Notre gestion est axée sur les
        personnes. En tant que groupe, nous nous efforçons de contribuer à
        l’enrichissement de l’existence et de la culture. Nous créons un style
        de vie pour les nouvelles générations, et nous fournissons une
        technologie avancée, des produits et des services de qualité.
      </p>
    </div>
  </div>
</div>


<div class="section">
  <div class="container">
    <div class="section">
      <wind-slick-multi-slider></wind-slick-multi-slider>
    </div>
  </div>
</div>

<wind-footer></wind-footer>

<div class="container"></div>
