<!-- Navbar -->
<nav>
  <div class="desktop row">
    <div class="col-md-2 logoHeader ">
      <img src="../../../../../assets/img/logo.png">
    </div>
    <div class="col-md-10 headermenu">

      <ul class="menuList">

        <li class="menuItem"> <a href="#" style="color: white;">ACCUEIL</a></li>
        <li class="menuItem"> <a href="/#/about-us"  style="color: white;">QUI SOMMES-NOUS ? </a> </li>
        <li class="menuItem"><a href="/#/products"  style="color: white;">NOS PRODUITS</a></li>
<!--        <li class="menuItem">nos solutions</li>-->
        <li class="menuItem"><a href="/#/contact-us"  style="color: white;">Contact</a></li>
        <li>
          <button class="searchButton">
            <img src="../../../../../assets/img/serach.png">
          </button>
        </li>
      </ul>

    </div>
  </div>

  <div class="mobile row">
    <div class="displayMenu">
      <img style="width: 100px;" src="../../../../../assets/img/logo.png">

      <div class="topnav">

        <button class="btnMenu" (click)="showmenu()">MENU</button>

        <ul class="ull" *ngIf="showmobileMenu">

          <li class="lii"> <a href="#" style="color: white;">ACCUEIL</a></li>
          <br>
          <li class="lii"> <a href="/#/about-us"  style="color: white;">QUI SOMMES-NOUS ? </a> </li>
          <br>
          <li class="lii"><a href="/#/products"  style="color: white;">NOS PRODUITS  </a> </li>
          <br>
<!--          <li class="lii">nos solutions</li>-->
<!--          <br>-->
          <li class="lii"><a href="/#/contact-us"  style="color: white;">Contact</a></li>
          <br>
          <li>
            <button class="searchButton">
              <img src="../../../../../assets/img/serach.png">
            </button>
          </li>
          <br>
        </ul>
      </div>
    </div>

  </div>
</nav>
<!-- End Navbar -->

